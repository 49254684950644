@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
@import url(https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600&display=swap);
.nav{
    height:150px;
    width:100vw;
}

.topNavbar{
    width:110%;
    height: 120px;
}

.logoCols{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    cursor: pointer;
}

.menuCols{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 18px;
}

.menuCols a{
    color:black;
}

.menuCols a:hover{
    color:grey;
}

.bottomNavbar{
    width: 110%;
    height: 30px;
}

.bottomNavbar > div{
    display: flex;
    align-items: center;
    color:white;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 16px;
}

.centerCol{
    justify-content: center;
}

.lastCol{
    justify-content: flex-end;
}

.pswp-thumbnail {
  display: inline-block;
  width: calc(33% - 10px);
  height: 300px;
  margin: 0 10px 10px 0;
  cursor: pointer;
}

.thumbnailImage{
    height:100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.frontBanner{
    margin-top:-100px;
    height:calc(100vh - 150px);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bannerText{
    font-weight: 600;
    font-family:"Source Sans", Arial, sans-serif;
    color:white;
    font-size: 50px;
    text-align: center;
}

.bannerButtons{
    width:100%;
    justify-content: center;
}

.bannerButton-parent{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerButton{
    border:2px solid white;
    border-radius: 20px;
    display: flex;
    font-family: "Source Sans", sans-serif;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    font-weight: 400;
    padding:10px 15px;
    cursor: pointer;
    background-color: transparent;
}

.bannerButton:hover{
    background-color: white;
    color:grey;
}

.bannerButton:focus {outline:0;}

.modalMain{
    font-family: "Source Sans", sans-serif;
}

.modalNumber{
    text-align: center;
    font-size: 50px;
    padding: 15px 0;
}

.homeicons{
    padding: 30px 0;
}

.homeicon button{
    border-color: white;
    color: white;
}

.homeicon button:hover{
    background-color:white;
    color: black;
}

.image-gallery-image{
    text-align: center;
}

.image-gallery-image img {
    width: auto !important;
    max-height: calc(100vh - 230px);
}

.image-gallery-thumbnails-container{
    display: flex;
}

.image-gallery-thumbnail img{
    width: auto !important;
    max-height: 80px;
}

.filters{
    padding: 0 0 50px;
}

.filters-title{
    font-size: 14px;
    font-weight: 600;
    padding-bottom:4px;
}

.filters-filters{
    height:30px;
    font-size: 18px;
    border: 2px solid black;
    width:100%;
}

.filters-filters:focus{
    outline: 0;
}

.filters-order{
    width:30px;
    height:30px;
    font-size: 18px;
    border: 2px solid black;
    border-left:none;
    background-color: white;
}

.filters-order:focus{
    outline: 0;
}

.filter-rows{
    margin: 0 -15px 20px -15px;
}

@media only screen and (max-width: 1000px){
    .nav{
        height:100px;
    }

    .mobileNavTop{
        height:100px;
        width:110%;
    }

    .frontBanner{
        margin-top:-150px;
    }

    .mobileTitle{
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        color: white;
        display: flex;
        align-items: center;
        font-size: 30px;
    }

    .mobileIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 3%;
        padding: 0 5%;
    }

    .mobileIcon i{
        font-size: 40px !important;
        color:white;
    }

    .mobileNavBottom{
        width:110%;
        background-color: white;
        display: none;
    }

    .mobileNavBottom .menuCols{
        font-size:20px;
        padding:2% 0;
    }

    .blogimage{
        height: calc(100vh - 200px);
    }

    .blogs:first-child{
        margin-top:-150px;
    }

    .blogs h2{
        font-size: 22px;
        font-weight: 800px;
        padding-bottom: 20px;
    }

    .bannerButton-parent{
        margin-bottom:10px;
    }

    .bannerText{
        font-size: 38px;
        padding:0 10%;
        width:100%;
    }

    .homeiconrow{
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        margin:0;
    }

    .homeicon{
        width:45%;
        margin: 3.5% 0.5%;
    }

    .contentrow {
        margin-bottom: 0;
    }

    .contentrow > div{
        margin-bottom:20px;
    }

    .mobfilter{
        margin-left:50%;
    }

    option{
        font-size: 14px;
    }
}

html{
    font-size: 16px;
}

body{
    padding-top:250px;
    font-family: 'Lora', Arial, Sans-serif;
}

a{
    text-decoration: none;
    color:white;
    float:right;
}

a:hover{
    text-decoration: none;
    color:white;
}

.nav{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: auto;
    background-color: white;
}

.navbanner{
    width: 100%;
    height: 150px;
    background-color: white;
}

.logo{
    background-image: url(/static/media/logo.b58dbe7c.jpg);
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: center;
}

.mobilebtn{
 	width:64px;
  	height:64px;
  	font-size:1.25rem;
  	color:white;
  	text-align:center;
  	padding: 16px;
  	display:none;
}

.mobilecell{
  	display:none;
  	float:left;
 	width:100vw;
  	background-color:white;
  	height:60px;
  	color:black;
  	text-align:center;
  	font-size: 1.25rem;
  	padding-top:15px;
}

.navbar{
    width: 100%;
    height: 30px;
    margin: 0;
    background-color: pink;
    color:white;
    text-align: right;
}

.navbar span{
    visibility: hidden;
    opacity: 0;
}

.navlist{
    width:80%;
    height: auto;
    margin: 0 10%;
    text-align: center;
    background-color: white;
    padding-bottom: 1.125rem;
}

.navcell{
    border-bottom: 1px solid pink;
    padding-top:8px;
    padding-bottom: 8px;
    font-size: 1.125rem;
}

.navcell:hover{
    border-bottom: 5px solid pink;
    padding-bottom: 3px;
    cursor: pointer;
}

.banner{
    height: 360px;
    background-color: #333333;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.4)), to(rgba(0,0,0,0.4))), url(/static/media/banner.c254816f.jpg);
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(/static/media/banner.c254816f.jpg);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 80px;
    background-position: center;
    background-size: cover;
}

.slogan{
    color:white;
    font-size: 1.25rem;
    font-weight: 300;
    padding:60px;
    display: table-cell;
    vertical-align: middle;
    width:100%;
    height: 100%;
}

.ctaButton{
    margin-top:180px;
    border: 2px solid white;
    height:40px;
    font-size: 1.25rem;
    line-height: 20px;
    color:white;
    padding:6px;
    text-align: center;
    background-color: transparent;
}

.ctaButton:focus {
    outline:0;
}

.ctaButton:hover{
    color:black;
    background-color: white;
    cursor:pointer;
}

.modal-backdrop{
    opacity: 0.8;
}

#ctaModal .modal-title{
    text-align:center;
    width: 450px;
    background-color: pink;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    border-radius: 8px;
    font-size: 1.875rem;
    line-height: 60px;
}

#ctaModal .modal-body{
    padding:10px;
}

#ctaModal .modal-body h2{
    width:100%;
    text-align:center;
}

#ctaModal .modal-body p{
    width:80%;
    margin-left:10%;
    margin-right:10%;
}

#flowerModal h6{
    text-align: center;
}

#flowerModal .flowerimg{
    width:100%;
    background-size: 100% auto;
    height:94px;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
}

#colourModal .colouricon{
    background-size: 100% auto;
    height:94px;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom:10px;
}

#colourModal .colourtext{
    display: table;
    height:94px;
    margin-bottom: 10px;
}

#colourModal .colourtext h6{
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
}

#deliveryModal .note{
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
}

.homeicons{
    text-align: center;
}

.homeicons h4{
    padding-bottom: 20px;
    font-weight: 400;
}

.homeicon{
    background-position: center;
    background-size: cover;
  	background-origin: content-box;
    background-repeat: no-repeat;
}

.homeicon .ctaButton{
    margin-top:calc(50% - 20px);
}

.helpbox{
    border: 2px solid lightgrey;
    text-align: center;
}

.helpbox h4{
    padding-top:10px;
    padding-bottom: 40px;
    font-weight: 400;
}

.helpicons{
    margin-bottom:40px;
}

.helpicon{
    height:150px;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-repeat: no-repeat;
    cursor: pointer;
}

.helptext{
    text-align: left;
    font-weight: 300;
    font-size: 0.875rem;
}

.helptext h5{
    font-weight:400;
}

.contentrow{
    margin-bottom: 30px;
}

.contentcell{
    display: table;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}

.contentcell:hover{
    transition: filter 0.2s, -webkit-filter 0.2s linear;
    filter: brightness(0.6);
    -webkit-filter: brightness(0.6);
    cursor: pointer;
}

.contentspan{
    display: table-cell;
    text-align: center;
    vertical-align: bottom;
    font-size:1.25rem;
    margin:5px;
    width: 100%;
    overflow-wrap: break-word;
}

.contentsub{
    font-size: 1rem;
    color:grey;
    overflow-wrap: break-word;
}

.blurb{
    margin-bottom: 80px;
    padding-left:8%;
    padding-right:8%;
}

.findnow{
    font-size: 1.35rem;
}

.intro{
    display:none;
}

.desc{
    margin-bottom:10%;
}

.desc h4:nth-child(2){
    font-weight: 400;
    color:black;
}

.desc h4:nth-child(4){
    font-weight: 100;
    color:black;
}

.desc h4{
    font-weight: 100;
    color:grey;
}

.desc h6{
    font-weight: 100;
    font-style: italic;
}

.descAcc a, .descFlo a{
    color:black;
    float: none;
}

#imgpar{
    margin-bottom:10%;
}

.img{
    height: calc(100% - 100px);
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.iconContainer{
    height:100px;
    width: 100%;
}

.icon{
    margin-top:20px;
    height:80px;
    width: 80px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    float:left;
    cursor: pointer;
}

.ctaButton{
    margin-top:20px;
    border: 2px solid black;
    background-color: transparent;
    color:black;
}

.ctaButton:focus {
    outline:0;
}

.ctaButton:hover{
    color:white;
    background-color: black;
    cursor:pointer;
}

.blogs{
    margin-bottom: 30px;
}

.blogrow{
    padding-top: 10px;
    padding-bottom: 10px;
}

.blogimage{
    background-clip:padding-box;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

