@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

.nav{
    height:150px;
    width:100vw;
}

.topNavbar{
    width:110%;
    height: 120px;
}

.logoCols{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    cursor: pointer;
}

.menuCols{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 18px;
}

.menuCols a{
    color:black;
}

.menuCols a:hover{
    color:grey;
}

.bottomNavbar{
    width: 110%;
    height: 30px;
}

.bottomNavbar > div{
    display: flex;
    align-items: center;
    color:white;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 16px;
}

.centerCol{
    justify-content: center;
}

.lastCol{
    justify-content: flex-end;
}

.pswp-thumbnail {
  display: inline-block;
  width: calc(33% - 10px);
  height: 300px;
  margin: 0 10px 10px 0;
  cursor: pointer;
}

.thumbnailImage{
    height:100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.frontBanner{
    margin-top:-100px;
    height:calc(100vh - 150px);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bannerText{
    font-weight: 600;
    font-family:"Source Sans", Arial, sans-serif;
    color:white;
    font-size: 50px;
    text-align: center;
}

.bannerButtons{
    width:100%;
    justify-content: center;
}

.bannerButton-parent{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerButton{
    border:2px solid white;
    border-radius: 20px;
    display: flex;
    font-family: "Source Sans", sans-serif;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    font-weight: 400;
    padding:10px 15px;
    cursor: pointer;
    background-color: transparent;
}

.bannerButton:hover{
    background-color: white;
    color:grey;
}

.bannerButton:focus {outline:0;}

.modalMain{
    font-family: "Source Sans", sans-serif;
}

.modalNumber{
    text-align: center;
    font-size: 50px;
    padding: 15px 0;
}

.homeicons{
    padding: 30px 0;
}

.homeicon button{
    border-color: white;
    color: white;
}

.homeicon button:hover{
    background-color:white;
    color: black;
}

.image-gallery-image{
    text-align: center;
}

.image-gallery-image img {
    width: auto !important;
    max-height: calc(100vh - 230px);
}

.image-gallery-thumbnails-container{
    display: flex;
}

.image-gallery-thumbnail img{
    width: auto !important;
    max-height: 80px;
}

.filters{
    padding: 0 0 50px;
}

.filters-title{
    font-size: 14px;
    font-weight: 600;
    padding-bottom:4px;
}

.filters-filters{
    height:30px;
    font-size: 18px;
    border: 2px solid black;
    width:100%;
}

.filters-filters:focus{
    outline: 0;
}

.filters-order{
    width:30px;
    height:30px;
    font-size: 18px;
    border: 2px solid black;
    border-left:none;
    background-color: white;
}

.filters-order:focus{
    outline: 0;
}

.filter-rows{
    margin: 0 -15px 20px -15px;
}
