@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i');

html{
    font-size: 16px;
}

body{
    padding-top:250px;
    font-family: 'Lora', Arial, Sans-serif;
}

a{
    text-decoration: none;
    color:white;
    float:right;
}

a:hover{
    text-decoration: none;
    color:white;
}

.nav{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: auto;
    background-color: white;
}

.navbanner{
    width: 100%;
    height: 150px;
    background-color: white;
}

.logo{
    background-image: url("images/static/logo.jpg");
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: center;
}

.mobilebtn{
 	width:64px;
  	height:64px;
  	font-size:1.25rem;
  	color:white;
  	text-align:center;
  	padding: 16px;
  	display:none;
}

.mobilecell{
  	display:none;
  	float:left;
 	width:100vw;
  	background-color:white;
  	height:60px;
  	color:black;
  	text-align:center;
  	font-size: 1.25rem;
  	padding-top:15px;
}

.navbar{
    width: 100%;
    height: 30px;
    margin: 0;
    background-color: pink;
    color:white;
    text-align: right;
}

.navbar span{
    visibility: hidden;
    opacity: 0;
}

.navlist{
    width:80%;
    height: auto;
    margin: 0 10%;
    text-align: center;
    background-color: white;
    padding-bottom: 1.125rem;
}

.navcell{
    border-bottom: 1px solid pink;
    padding-top:8px;
    padding-bottom: 8px;
    font-size: 1.125rem;
}

.navcell:hover{
    border-bottom: 5px solid pink;
    padding-bottom: 3px;
    cursor: pointer;
}

.banner{
    height: 360px;
    background-color: #333333;
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url("images/static/banner.jpg");
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 80px;
    background-position: center;
    background-size: cover;
}

.slogan{
    color:white;
    font-size: 1.25rem;
    font-weight: 300;
    padding:60px;
    display: table-cell;
    vertical-align: middle;
    width:100%;
    height: 100%;
}

.ctaButton{
    margin-top:180px;
    border: 2px solid white;
    height:40px;
    font-size: 1.25rem;
    line-height: 20px;
    color:white;
    padding:6px;
    text-align: center;
    background-color: transparent;
}

.ctaButton:focus {
    outline:0;
}

.ctaButton:hover{
    color:black;
    background-color: white;
    cursor:pointer;
}

.modal-backdrop{
    opacity: 0.8;
}

#ctaModal .modal-title{
    text-align:center;
    width: 450px;
    background-color: pink;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    border-radius: 8px;
    font-size: 1.875rem;
    line-height: 60px;
}

#ctaModal .modal-body{
    padding:10px;
}

#ctaModal .modal-body h2{
    width:100%;
    text-align:center;
}

#ctaModal .modal-body p{
    width:80%;
    margin-left:10%;
    margin-right:10%;
}

#flowerModal h6{
    text-align: center;
}

#flowerModal .flowerimg{
    width:100%;
    background-size: 100% auto;
    height:94px;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
}

#colourModal .colouricon{
    background-size: 100% auto;
    height:94px;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom:10px;
}

#colourModal .colourtext{
    display: table;
    height:94px;
    margin-bottom: 10px;
}

#colourModal .colourtext h6{
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
}

#deliveryModal .note{
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
}

.homeicons{
    text-align: center;
}

.homeicons h4{
    padding-bottom: 20px;
    font-weight: 400;
}

.homeicon{
    background-position: center;
    background-size: cover;
  	background-origin: content-box;
    background-repeat: no-repeat;
}

.homeicon .ctaButton{
    margin-top:calc(50% - 20px);
}

.helpbox{
    border: 2px solid lightgrey;
    text-align: center;
}

.helpbox h4{
    padding-top:10px;
    padding-bottom: 40px;
    font-weight: 400;
}

.helpicons{
    margin-bottom:40px;
}

.helpicon{
    height:150px;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    background-repeat: no-repeat;
    cursor: pointer;
}

.helptext{
    text-align: left;
    font-weight: 300;
    font-size: 0.875rem;
}

.helptext h5{
    font-weight:400;
}

.contentrow{
    margin-bottom: 30px;
}

.contentcell{
    display: table;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}

.contentcell:hover{
    transition: filter 0.2s, -webkit-filter 0.2s linear;
    filter: brightness(0.6);
    -webkit-filter: brightness(0.6);
    cursor: pointer;
}

.contentspan{
    display: table-cell;
    text-align: center;
    vertical-align: bottom;
    font-size:1.25rem;
    margin:5px;
    width: 100%;
    overflow-wrap: break-word;
}

.contentsub{
    font-size: 1rem;
    color:grey;
    overflow-wrap: break-word;
}

.blurb{
    margin-bottom: 80px;
    padding-left:8%;
    padding-right:8%;
}

.findnow{
    font-size: 1.35rem;
}

.intro{
    display:none;
}

@import "new.css";
@import "mobile.css";
