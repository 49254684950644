.blogs{
    margin-bottom: 30px;
}

.blogrow{
    padding-top: 10px;
    padding-bottom: 10px;
}

.blogimage{
    background-clip:padding-box;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
