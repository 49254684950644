@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600&display=swap');

@media only screen and (max-width: 1000px){
    .nav{
        height:100px;
    }

    .mobileNavTop{
        height:100px;
        width:110%;
    }

    .frontBanner{
        margin-top:-150px;
    }

    .mobileTitle{
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        color: white;
        display: flex;
        align-items: center;
        font-size: 30px;
    }

    .mobileIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 3%;
        padding: 0 5%;
    }

    .mobileIcon i{
        font-size: 40px !important;
        color:white;
    }

    .mobileNavBottom{
        width:110%;
        background-color: white;
        display: none;
    }

    .mobileNavBottom .menuCols{
        font-size:20px;
        padding:2% 0;
    }

    .blogimage{
        height: calc(100vh - 200px);
    }

    .blogs:first-child{
        margin-top:-150px;
    }

    .blogs h2{
        font-size: 22px;
        font-weight: 800px;
        padding-bottom: 20px;
    }

    .bannerButton-parent{
        margin-bottom:10px;
    }

    .bannerText{
        font-size: 38px;
        padding:0 10%;
        width:100%;
    }

    .homeiconrow{
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        margin:0;
    }

    .homeicon{
        width:45%;
        margin: 3.5% 0.5%;
    }

    .contentrow {
        margin-bottom: 0;
    }

    .contentrow > div{
        margin-bottom:20px;
    }

    .mobfilter{
        margin-left:50%;
    }

    option{
        font-size: 14px;
    }
}
