.desc{
    margin-bottom:10%;
}

.desc h4:nth-child(2){
    font-weight: 400;
    color:black;
}

.desc h4:nth-child(4){
    font-weight: 100;
    color:black;
}

.desc h4{
    font-weight: 100;
    color:grey;
}

.desc h6{
    font-weight: 100;
    font-style: italic;
}

.descAcc a, .descFlo a{
    color:black;
    float: none;
}

#imgpar{
    margin-bottom:10%;
}

.img{
    height: calc(100% - 100px);
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.iconContainer{
    height:100px;
    width: 100%;
}

.icon{
    margin-top:20px;
    height:80px;
    width: 80px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    float:left;
    cursor: pointer;
}

.ctaButton{
    margin-top:20px;
    border: 2px solid black;
    background-color: transparent;
    color:black;
}

.ctaButton:focus {
    outline:0;
}

.ctaButton:hover{
    color:white;
    background-color: black;
    cursor:pointer;
}
